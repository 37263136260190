import Link from "next/link";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import dynamic from "next/dynamic";

const CallUs = dynamic(() => import("../../components/call-us/callUs"));

const Hero = () => {
  const { t } = useTranslation("home");

  return (
    <section className="relative pb-10 pt-20 md:pt-32 h-1527">
      <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 block dark:hidden h-full">
        <img
          src="/images/gradient.jpg"
          alt="Sofia to bansko taxi, bansko to sofia taxi"
          className="h-full w-full"
        />
      </picture>
      <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
        <img
          src="/images/gradient_dark.jpg"
          alt="bansko to sofia transfer, taxi from bansko to sofia airport"
          className="h-full w-full"
          loading="lazy"
        />
      </picture>
      <div className="container h-full mx-auto">
        <div className="grid h-full items-center gap-4 md:grid-cols-12">
          <div className="col-span-6 flex h-full flex-col items-center justify-center py-10 md:items-start md:py-15 xl:col-span-4">
            <h1
              aria-label="Website title"
              className="text-jacarta-700 font-bold font-display mb-6 text-center text-3xl dark:text-white md:text-left lg:text-4xl xl:text-5xl"
            >
              {t("title")}
            </h1>
            <p
              aria-label="Website sub title"
              className="dark:text-jacarta-200 mb-8 text-center text-lg md:text-left"
            >
              {t("sub-title")}
            </p>
            <div className="flex flex-col">
              <div className="flex justify-center space-x-4">
                <Link href="/book">
                  <a
                    aria-label="Book now button"
                    className="bg-[#000000] shadow-accent-volume hover:bg-jacarta-600 w-44 font-bold rounded-full py-3 px-8 text-center text-[#9E89DE] uppercase transition-all"
                  >
                    {t("book-btn")}
                  </a>
                </Link>
                <Link href="/routes">
                  <a
                    aria-label="Check routes and prices button"
                    className="text-white hover:bg-jacarta-600 w-36 rounded-full bg-[#000000] py-3 px-8 text-center font-bold transition-all"
                  >
                    {t("check-prices-btn")}
                  </a>
                </Link>
              </div>
              <CallUs />
            </div>
          </div>
          <div className="col-span-6 xl:col-span-8">
            <div className="relative text-center md:pl-8 md:text-right">
              <Image
                src="/images/hero/hero.svg"
                width={560}
                height={560}
                priority
                alt={t("img-alt.hero-1")}
                className="mt-8 inline-block w-72 sm:w-full lg:w-[24rem] xl:w-[35rem]"
              />
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                src="/images/hero/3D_elements.webp"
                width={740}
                height={602}
                alt={t("img-alt.hero-2")}
                className="animate-fly absolute top-0 md:-right-[10%]"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
