
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from "react";
import { Hero } from "../../components/component";
import Meta from "../../components/Meta";
import dynamic from "next/dynamic";
import useTranslation from "next-translate/useTranslation";
const Process = dynamic(() => import("../../components/blog/process"));
const Info = dynamic(() => import("../../components/about/Info"));
const BlogLinks = dynamic(() => import("../../components/blog/blogLinks"));
const Bids = dynamic(() => import("../../components/bids/bids"));
const Home = () => {
    const { t } = useTranslation("home");
    return (<main>
      <Meta title={t("meta.title")} desc={t("meta.desc")} cannonicalSubPath=""/>
      <Hero />
      <Bids />
      <Process />
      <Info />
      <BlogLinks />
    </main>);
};
export default Home;

    async function __Next_Translate__getStaticProps__192fe5b5ac4__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/home/home',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__192fe5b5ac4__ as getStaticProps }
  