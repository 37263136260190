
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React, { useContext, useEffect } from "react";
import UserContext from "../components/UserContext";
import Home from "./home/home";
export default function HomeHandler() {
    const { scrollRef } = useContext(UserContext);
    useEffect(() => {
        window.scrollTo(0, scrollRef.current.scrollPos);
        const handleScrollPos = () => {
            scrollRef.current.scrollPos = window.scrollY;
        };
        window.addEventListener("scroll", handleScrollPos);
        return () => {
            window.removeEventListener("scroll", handleScrollPos);
        };
    });
    return (<>
      <Home />
    </>);
}

    async function __Next_Translate__getStaticProps__192fe5b5a1e__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__192fe5b5a1e__ as getStaticProps }
  