import "swiper/css";
import "swiper/css/navigation";
import useTranslation from "next-translate/useTranslation";
import dynamic from "next/dynamic";

const BidsCarousel = dynamic(() => import("../carousel/bidsCarousel"));
const HeadLine = dynamic(() => import("../headLine"));

const Bids = ({ classes = "pt-10 pb-24", bgWhite }) => {
  const { t } = useTranslation("home");

  return (
    <section className={classes}>
      {/* <!-- Hot Bids --> */}
      {bgWhite && (
        <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
          <img
            src="/images/gradient_light.jpg"
            alt="two way trip transfers bansko sofia, airport shuttle bulgaria"
            className="h-full w-full"
          />
        </picture>
      )}
      <div className="container">
        <HeadLine
          text={t("popular-title")}
          image="https://cdn.jsdelivr.net/npm/emoji-datasource-apple@7.0.2/img/apple/64/1f525.png"
          classes="flex items-center justify-center font-display text-jacarta-700 mb-8 text-3xl dark:text-white"
        />
        <div className="block mb-4">
          <p className="text-center">{t("popular-semi-title")}</p>
        </div>
        <div className="relative">
          {/* <!-- Slider --> */}
          <BidsCarousel />
        </div>
      </div>
      {/* <!-- end hot bids --> */}
    </section>
  );
};

export default Bids;
